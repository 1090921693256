import { VNode } from 'vue';

export interface IPlugin {
    name: string;
    text: string;
    disabled?: boolean;
    node: VNode;
}

export interface VueImagePickerPlugin {
    getValues?: () => Image[];
    showed: boolean;
}

export interface Page<T> {
    total: number;
    offset: number;
    items: T[];
}

export interface PickerUrlSettings {
    onEnter?: (url: string) => Promise<void>;

    buttonCustomClass?: string;
    inputCustomClass?: string;
    strings?: { [key: string]: string };
}

export interface PickerUploadSettings {
    uploadHandler?: (file: File) => Promise<void>;
    // OR
    uploadUrl?: string;
    defaultHeaders?: { [key: string]: string } | (() => { [key: string]: string });
    uploadSuccessHandler?: (image: Image, idx: number) => Promise<void>;
    uploadErrorHandler?: (file: File, idx: number, status: number, response: any) => Promise<void>;

    multiple?: boolean;
    accept?: string;

    buttonCustomClass?: string;
    strings?: { [key: string]: string };
}

export interface Image {
    id?: string;
    name?: string;
    downloadUrl: string;
}

export interface PickerLibrarySettings {
    pageSize: number;
    getHandler: (offset: number, count: number) => Promise<Page<Image>>;
    onImageSelected?: (image: Image) => Promise<void>;
    multiple?: boolean;
}

export class ImageViewModel {
    public selected: boolean = false;
    private _state: Image;

    constructor(state: Image) {
        this._state = state;
    }

    public get state() {
        return this._state;
    }

    public get url(): string {
        return this._state.downloadUrl;
    }

    public get id(): string | undefined {
        return this._state.id;
    }

    public select() {
        this.selected = true;
    }

    public unselect() {
        this.selected = false;
    }

    public switch() {
        this.selected = !this.selected;
    }
}
