
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Page, Image, ImageViewModel, VueImagePickerPlugin } from './types';
import { VueConstructor } from 'vue';
import InfiniteLoading from 'vue-infinite-loading';

@Component({
    components: {
        InfiniteLoading,
    },
})
export default class PickerLibrary extends Vue implements VueImagePickerPlugin {
    public name: string = 'library';

    public showed: boolean = false;

    @Prop()
    private getHandler: (offset: number, count: number) => Promise<Page<Image>>;

    @Prop()
    private onImageSelected: (image: Image) => Promise<void>;

    @Prop({ default: false })
    private multiple: boolean;

    @Prop({ default: 20 })
    private pageSize: number;

    private offset: number = 0;
    private total: number = -1;
    private images: ImageViewModel[] = [];

    private loading: boolean = false;

    private invisibleSelected: Image[] = [];

    private errorMessage: string | null = null;

    public unshift(...images: Image[]) {
        images.forEach(image => {
            const index = this.images.findIndex(img => img.id === image.id);
            if (index !== -1) {
                return;
            }
            this.images.unshift(new ImageViewModel(image));
        });

        this.total += 1;
        this.offset += 1;
    }

    public select(...images: Image[] | ImageViewModel[]) {
        this.selectForced(false, ...images);
    }

    public getValues(): Image[] {
        return this.images
            .filter(img => img.selected)
            .map(img => img.state)
            .concat(this.invisibleSelected);
    }

    private selectForced(forcedUnselect: boolean = false, ...images: Image[] | ImageViewModel[]) {
        if (images.length === 0) {
            return;
        }

        if (!this.multiple || forcedUnselect) {
            this.images
                .filter(img => img.selected && img.id !== images[0].id)
                .forEach(img => img.unselect());
            this.invisibleSelected = [];
            this.selectImage(images[0]);

            // if (images[0].id) {
            //     const element = (this.$refs.images as Element).children[`${images[0].id}`] as Element;
            //     if (element) {
            //         element.scrollIntoView({ behavior: 'smooth' });
            //     }
            // }
            return;
        }

        images.forEach((image: Image | ImageViewModel) => {
            this.selectImage(image);
        });
    }

    private selectImage(image: Image | ImageViewModel) {
        if (image instanceof ImageViewModel) {
            image.select();
            if (this.onImageSelected) {
                this.onImageSelected(image.state);
            }
            return;
        }

        const idx = this.images.findIndex(img => img.id === image.id);
        if (idx === -1) {
            this.invisibleSelected.push(image);
        } else {
            this.images[idx].select();
            if (this.onImageSelected) {
                this.onImageSelected(image);
            }
        }
    }

    private toggle(image: ImageViewModel, event: MouseEvent) {
        if (!this.multiple || !event.ctrlKey) {
            this.selectForced(true, image);
            return;
        }

        image.switch();

        if (image.selected && this.onImageSelected) {
            this.onImageSelected(image.state);
        }
    }

    private async get(offset: number, count: number) {
        this.loading = true;
        this.errorMessage = null;

        let page: Page<Image>;
        try {
            page = await this.getHandler(offset, count);
        } catch (error: any) {
            this.loading = false;
            this.errorMessage = error.message;
            return;
        }

        this.total = page.total;
        let idx = -1;
        if (page.items.length > 0) {
            idx = this.images.findIndex(img => img.id === page.items[0].id);
        }

        for (let i = Math.max(0, idx); i < page.items.length; i++) {
            const vm = new ImageViewModel(page.items[i]);
            const invIndex = this.invisibleSelected.findIndex(img => img.id === vm.id);
            if (invIndex !== -1) {
                vm.selected = true;
                this.invisibleSelected.splice(invIndex, 1);
            }
            this.images.push(vm);
        }

        this.loading = false;
    }

    private async infiniteLoad(state: any) {
        if (this.loading) {
            return;
        }

        if (this.total === this.images.length) {
            state.complete();
        }

        await this.get(this.images.length, this.pageSize);

        if (this.total <= this.images.length) {
            state.complete();
        } else {
            state.loaded();
        }
    }

    private clearError() {
        this.errorMessage = null;
    }
}
